<template>
  <div class="picture">
    <img src="./../assets/images/welcome.png"/>
  </div>
</template>


<script>
export default {
  name: 'welcome'
}
</script>

<style scoped>
.picture{
  text-align: center;
  background-color: #fff;
  height: 100%;
}
</style>